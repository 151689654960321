<template>
  <div
    style="text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4)"
    class="absolute top-2 z-50 flex flex-col block mx-2 mb-2 font-mono text-xs text-white select-none left-[18.25rem]"
    :class="{ 'mb-12': offsetBottom }"
  >
    <div v-if="messages" class="m-2 select-none">
      <template v-for="message in messages">
        <MessageLine :message="message" />
      </template>
    </div>

    <div
      v-if="isLoading && messages"
      class="my-2 border-t border-gray-100 opacity-25"
    />

    <div v-if="isLoading" class="m-2">
      {{ loadingVerb }} {{ loadingPc }}%
      <span
        class="relative inline-block w-32 h-1 bg-white bg-opacity-50 rounded-sm"
      >
        <span
          class="absolute inline-block h-1 bg-blue-500"
          :style="loadingBarStyle"
        />
      </span>
    </div>
  </div>
</template>
<script>
const MessageLine = () => import('./MessageLine')

export default {
  name: 'MessagesOverlay',

  components: {
    MessageLine
  },

  props: {
    offsetBottom: {
      type: Boolean,
      default: false
    },

    loadingMessage: {
      type: Object,
      required: false
    },

    messages: {
      type: Array,
      required: false
    }
  },

  computed: {
    isLoading() {
      return this.loadingPc > 0
    },

    loadingPc() {
      if (this.loadingMessage) {
        return this.loadingMessage.pc
      }
      return 0
    },

    loadingVerb() {
      if (this.loadingMessage) {
        return this.loadingMessage.message
      }

      return 'Loading'
    },

    loadingBarStyle() {
      return 'width: ' + this.loadingPc + '%'
    }
  }
}
</script>
